export function handleApiErrors(errorResponse) {
  let errors = {};
  if (errorResponse.response && errorResponse.response.data) {
    const data = errorResponse.response.data;
    const violations = data.violations || data.errors;
    if (violations) {
      violations.forEach((violation) => {
        errors[violation.field] = violation.message || violation.defaultMessage;
      });
    } else if (errorResponse.response.data.detail) {
      errors.COMMON = errorResponse.response.data.detail;
    }
  }
  return errors;
}
