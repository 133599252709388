import { OCPP_API } from "@/api/common/http-common";

export function getChargerSites() {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.get(`${tenant}/assets/v1.0/tenants/${tenant}/chargersites`);
}

export function createChargerSite(siteData) {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.post(
    `${tenant}/assets/v1.0/tenants/${tenant}/chargersites`,
    siteData
  );
}

export function updateChargerSite(id, siteData) {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.put(
    `${tenant}/assets/v1.0/tenants/${tenant}/chargersites/${id}`,
    siteData
  );
}

export function deleteChargerSite(id) {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.delete(
    `${tenant}/assets/v1.0/tenants/${tenant}/chargersites/${id}`
  );
}

export function assignChargerSite(chargerId, chargerSiteId) {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.put(
    `${tenant}/assets/chargers/${chargerId}/chargersites/${chargerSiteId}`
  );
}

export function unassignChargerSite(chargerId) {
  const tenant = process.env.VUE_APP_TENANT;

  return OCPP_API.delete(`${tenant}/assets/chargers/${chargerId}/chargersites`);
}

export function downloadReportForSite(siteId, year, month) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(
    `${tenant}/consumptionReport/chargersites/${siteId}?year=${year}&month=${month}&reportType=PDF`,
    { responseType: "blob" }
  );
}
