<template>
  <v-app id="chargerSitesView">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
          solo
          dense
          class="pl-5 pr-5"
          test-id="search-input"
        ></v-text-field>

        <div class="text-right">
          <b-button
            size="sm"
            class="text-success border border-success"
            @click="openChargerSiteModal()"
            >Add site
          </b-button>
        </div>
      </v-card-title>

      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :fields="headers"
        :items="chargerSites"
        :search="search"
        :custom-filter="customSearch"
        :expanded.sync="expanded"
        item-key="id"
        :footer-props="{
          'items-per-page-options': [20, 40, 60, -1],
        }"
        :items-per-page="20"
        dense
        class="elevation-0 row-higher"
        test-id="charger-sites-table"
      >
        <template v-slot:item.name="slotData">
          <div @click="expandRow(slotData)" class="clickable">
            <span class="font-weight-bold">
              <font-awesome-icon
                v-if="isExpanded(slotData)"
                icon="chevron-up"
              ></font-awesome-icon>
              <font-awesome-icon v-else icon="chevron-down"></font-awesome-icon>
              {{
                `${slotData.item.name}${
                  !slotData.item.email ? "" : " - (" + slotData.item.email + ")"
                }`
              }}</span
            ><span>{{ ` (${findChargersCount(slotData.item.id)})` }}</span>
          </div>
        </template>

        <template v-slot:item.id="{ item }">
          <span
            @click="openAssignChargerModal(item)"
            class="icon-bigger icon-green pr-3 clickable"
            title="Add charger to this site"
            ><font-awesome-icon icon="plus-circle"
          /></span>
          <span
            @click="openChargerSiteModal(item)"
            class="icon-bigger icon-blue pr-3 clickable"
            title="Edit charger site"
            ><font-awesome-icon icon="edit"
          /></span>
          <span
            @click="openDeleteSiteModal(item)"
            class="icon-bigger icon-red pr-3 clickable"
            title="Delete charger site"
            ><font-awesome-icon icon="trash"
          /></span>
          <span
            @click="downloadReport(item.id, item.name)"
            :class="`${
              findChargersCount(item.id) > 0
                ? 'icon-black clickable'
                : 'icon-grey'
            } icon-bigger`"
            title="Download report for this site"
            ><font-awesome-icon icon="download"
          /></span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-iterator :items="chargersBySite[item.id] || []">
              <template v-slot:no-data>
                <small>No chargers</small>
              </template>
              <template v-slot:default="{ items }">
                <b-table
                  striped
                  hover
                  :items="items"
                  :fields="chargersHeaders"
                  test-id="chargers-table"
                >
                  <template #cell(id)="data">
                    <div class="text-right">
                      <span
                        @click="openUnassignChargerModal(data.item)"
                        class="icon-red clickable pr-3"
                        title="Remove charger from this site"
                        ><font-awesome-icon icon="trash"
                      /></span>
                    </div>
                  </template>
                </b-table>
              </template>
            </v-data-iterator>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <b-modal
      id="add-charger-site-modal"
      ref="add-charger-site-modal"
      v-model="isModalVisible.CHARGER_SITE_MODAL"
      hide-footer
      :title="this.selectedSite ? 'Edit charger site' : 'Add charger site'"
    >
      <template>
        <b-form @submit.stop.prevent="saveChargerSite()">
          <b-form-group id="name" label="Site name" label-for="name">
            <b-form-input id="name" v-model="chargerSiteForm.name" />
            <span class="text-error" v-show="this.errors.name">{{
              this.errors.name
            }}</span>
          </b-form-group>
          <b-form-group id="email" label="Site report email" label-for="email">
            <b-form-input id="email" v-model="chargerSiteForm.email" />
            <span class="text-error" v-show="this.errors.email">{{
              this.errors.email
            }}</span>
          </b-form-group>
          <span class="text-error" v-show="this.errors.COMMON">{{
            this.errors.COMMON
          }}</span>
          <b-button
            size="sm"
            variant="success"
            type="submit"
            class="float-right"
            test-id="cm001-modal-submit"
            >Submit
          </b-button>
        </b-form>
      </template>
    </b-modal>

    <b-modal
      id="assign-charger-modal"
      ref="assign-charger-modal"
      v-model="isModalVisible.ASSIGN_CHARGER"
      hide-footer
      :title="`Add a charger to ${
        this.selectedSite ? this.selectedSite.name : null
      } site`"
    >
      <template>
        <b-form @submit.stop.prevent="assignChargerToSite()">
          <b-form-group
            id="charger"
            label="Charger serial number"
            label-for="chargerSelect"
          >
            <multiselect
              v-model="selectedCharger"
              id="chargerSelect"
              placeholder="Search..."
              :options="chargers.filter((charger) => !charger.chargerSite)"
              label="serialNumber"
              optionValue="id"
              :allow-empty="false"
              deselect-label=""
            />
            <span class="text-error" v-show="this.errors.COMMON">{{
              this.errors.COMMON
            }}</span>
          </b-form-group>
          <b-button
            size="sm"
            variant="success"
            type="submit"
            class="float-right"
            test-id="cm002-modal-submit"
            >Submit
          </b-button>
        </b-form>
      </template>
    </b-modal>

    <ConfirmationModal
      :modal-name="confirmUnassignChargerName"
      :title="`Remove charger ${
        this.selectedCharger ? this.selectedCharger.serialNumber : null
      } from a site`"
      :callbackAction="unassignChargerFromSite"
    >
      You are about to remove charger
      {{ this.selectedCharger ? this.selectedCharger.serialNumber : null }}
      from this site. Are you sure you want to do that?
    </ConfirmationModal>

    <ConfirmationModal
      :modal-name="confirmDeleteSiteName"
      :title="`Delete site ${
        this.selectedSite ? this.selectedSite.name : null
      }`"
      :callbackAction="removeChargerSite"
    >
      You are about to delete site
      {{ this.selectedSite ? this.selectedSite.name : null }}. All chargers
      assigned to this site will be unassigned and become siteless. Are you sure
      you want to do that?
    </ConfirmationModal>
  </v-app>
</template>

<script>
import { subMinutes, differenceInMilliseconds } from "date-fns";
import {
  getChargerSites,
  createChargerSite,
  assignChargerSite,
  unassignChargerSite,
  downloadReportForSite,
  updateChargerSite,
  deleteChargerSite,
} from "@/api/ChargerSitesApi";
import { getChargers } from "@/api/ChargersListApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusCircle,
  faDownload,
  faTrash,
  faChevronUp,
  faChevronDown,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { handleApiErrors } from "@/utils/errorUtils";
import ConfirmationModal from "./utils/ConfirmationModal.vue";

library.add(
  faPlusCircle,
  faDownload,
  faTrash,
  faChevronUp,
  faChevronDown,
  faEdit
);

export default {
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    multiselect: Multiselect,
    ConfirmationModal,
  },
  name: "chargerSitesTable",
  data() {
    return {
      moment: moment,
      search: "",
      expanded: [],
      chargerSites: [],
      selectedSite: null,
      selectedCharger: null,
      chargers: [],
      chargersBySite: {},
      isLoading: true,
      chargerSiteForm: this.emptyChargerSiteForm(),
      isModalVisible: {
        CHARGER_SITE_MODAL: false,
        ASSIGN_CHARGER: false,
      },
      confirmUnassignChargerName: "confirm-unassign-charger-modal",
      confirmDeleteSiteName: "confirm-delete-site-modal",
      errors: {},
      chargersHeaders: [
        {
          key: "serialNumber",
          label: "",
        },
        {
          key: "id",
          label: "",
        },
      ],
      headers: [
        {
          text: "Charger sites",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "id",
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      await this.fetchChargerSites();
      this.fetchChargers();
    },
    fetchChargerSites() {
      getChargerSites().then((response) => {
        this.chargerSites = response.data;
      });
    },
    fetchChargers() {
      this.isLoading = true;
      getChargers()
        .then((response) => {
          this.chargers = response.data;
          this.chargersBySite = Object.groupBy(response.data, (charger) =>
            charger.chargerSite ? charger.chargerSite.id : "undefined"
          );
        })
        .catch((error) => console.log(error))
        .finally(() => (this.isLoading = false));
    },
    saveChargerSite() {
      this.errors = {};
      this.isLoading = true;

      const updateOrCreateFunction = this.selectedSite
        ? updateChargerSite(this.selectedSite.id, this.chargerSiteForm)
        : createChargerSite(this.chargerSiteForm);

      updateOrCreateFunction
        .then(() => {
          this.fetchChargerSites();
          this.showSuccessMessage();
          this.hideModal("CHARGER_SITE_MODAL");
        })
        .catch(this.handleErrors)
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeChargerSite() {
      if (!this.selectedSite) {
        return;
      }

      this.isLoading = true;
      deleteChargerSite(this.selectedSite.id)
        .then(() => {
          this.fetchChargerSites();
          this.showSuccessMessage();
          this.$bvModal.hide(this.confirmDeleteSiteName);
          this.selectedSite = null;
        })
        .catch(this.handleErrors)
        .finally(() => {
          this.isLoading = false;
        });
    },
    assignChargerToSite() {
      this.errors = {};
      if (!this.selectedSite || !this.selectedCharger) {
        this.errors.COMMON = `Select a ${
          !this.selectedSite ? "site" : "charger"
        }!`;
        return;
      }
      this.isLoading = true;
      assignChargerSite(this.selectedCharger.id, this.selectedSite.id)
        .then((response) => {
          this.fetchChargers();
          this.showSuccessMessage();
          this.hideModal("ASSIGN_CHARGER");
        })
        .catch(this.handleErrors)
        .finally(() => {
          this.isLoading = false;
        });
    },
    unassignChargerFromSite() {
      this.errors = {};
      if (!this.selectedCharger) {
        this.errors.COMMON = `Charger was not selected!`;
        return;
      }
      this.isLoading = true;
      unassignChargerSite(this.selectedCharger.id)
        .then((response) => {
          this.fetchChargers();
          this.showSuccessMessage();
          this.$bvModal.hide(this.confirmUnassignChargerName);
        })
        .catch(this.handleErrors)
        .finally(() => {
          this.isLoading = false;
        });
    },
    downloadReport(siteId, siteName) {
      if (this.findChargersCount(siteId) === 0) {
        return "#";
      }

      const refDate = moment().subtract(1, "month");
      const year = refDate.format("YYYY");
      const month = refDate.format("MMMM");

      this.isLoading = true;
      downloadReportForSite(siteId, year, month)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          let url = window["URL"].createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `Report_site_${siteName}.pdf`;
          a.click();
          window["URL"].revokeObjectURL(url);
        })
        .catch(this.handleErrors)
        .finally(() => (this.isLoading = false));
    },
    handleErrors(error) {
      const apiErrors = handleApiErrors(error);
      if (Object.keys(apiErrors).length !== 0) {
        this.errors = apiErrors;
      } else {
        this.showErrorMessage();
      }
    },
    isExpanded(slot) {
      return this.expanded.findIndex((i) => i === slot.item) > -1;
    },
    expandRow(slot) {
      const indexExpanded = this.expanded.findIndex((i) => i === slot.item);
      if (this.isExpanded(slot)) {
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(slot.item);
      }
    },
    customSearch(value, search, item) {
      const lowerSearch = search.toLowerCase();
      return (
        value.toLowerCase().includes(lowerSearch) ||
        (this.chargersBySite[item.id] || []).some(this.filterChargerCondition)
      );
    },
    filterChargerCondition(charger) {
      return charger.serialNumber
        .toLowerCase()
        .includes(this.search.toLowerCase());
    },
    findChargersCount(siteId) {
      return (this.chargersBySite[siteId] || []).length;
    },
    emptyChargerSiteForm() {
      return {
        name: "",
        email: "",
      };
    },
    initialChargerSiteForm(site) {
      return {
        name: site.name,
        email: site.email,
      };
    },
    openChargerSiteModal(site) {
      this.selectedSite = site || null;
      this.chargerSiteForm = site
        ? this.initialChargerSiteForm(site)
        : this.emptyChargerSiteForm();
      this.showModal("CHARGER_SITE_MODAL");
    },
    openAssignChargerModal(selectedSite) {
      this.selectedSite = selectedSite;
      this.showModal("ASSIGN_CHARGER");
    },
    openUnassignChargerModal(selectedCharger) {
      this.selectedCharger = selectedCharger;
      this.$bvModal.show(this.confirmUnassignChargerName);
    },
    openDeleteSiteModal(selectedSite) {
      this.selectedSite = selectedSite;
      this.$bvModal.show(this.confirmDeleteSiteName);
    },
    showModal(modalType) {
      this.errors = {};
      this.isModalVisible[modalType] = true;
    },
    hideModal(modalType) {
      this.isModalVisible[modalType] = false;
      this.selectedSite = null;
      this.selectedCharger = null;
      this.errors = {};
    },
    showSuccessMessage() {
      this.$emit("send-message", true);
    },
    showErrorMessage() {
      this.$emit("send-message", false);
    },
  },
  computed: {},
  mounted() {
    this.fetchData();
    const tokenExpiryDate = localStorage.getItem("tokenExpiry");
    const twoMinutesBeforeExpiry = subMinutes(Date.parse(tokenExpiryDate), 2);
    const diff = differenceInMilliseconds(twoMinutesBeforeExpiry, Date.now());
    if (diff > 0) {
      setInterval(() => location.reload(), diff);
    }
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #0fac5f;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #374454;
}

.v-data-table.row-higher td {
  height: 3rem !important;
}

.icon-bigger {
  font-size: 1.3rem;
}

.icon-green {
  color: #0fac5f;
}

.icon-blue {
  color: darkblue;
}

.icon-grey {
  color: grey;
}

.icon-black {
  color: black;
}

.icon-red {
  color: #ff4d4d;
}
</style>
