<template>
  <b-modal :id="modalName" :ref="modalName" hide-footer :title="title">
    <template>
      <div class="mb-3">
        <slot />
      </div>
      <b-button
        @click="callbackAction"
        size="sm"
        variant="success"
        class="float-right"
        test-id="confirmation-modal-submit"
        >Yes, do it!
      </b-button>
      <b-button
        @click="hideModal"
        size="sm"
        variant="success"
        test-id="confirmation-modal-cancel"
        >Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["modalName", "title", "callbackAction"],
  methods: {
    hideModal() {
      this.$bvModal.hide(this.modalName);
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
